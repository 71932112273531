<template>
  <div class="auth">
    <AuthComp />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthComp from '@/components/AuthComp.vue'

export default {
  name: 'auth',
  components: {
    AuthComp
  }
}
</script>
