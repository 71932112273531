<template>
  <v-container>
    <v-layout
      text-center
      wrap
    >
      <v-card
        v-if="nouuid"
        class="mx-auto my-12"
        style="min-width:350px;max-width:350px;"
      >
        <v-card-title>
          <span class="headline">Audit Review Scheduler</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <p v-if="resent" class="body-1 text-left">If address is valid then the original email with the link has been sent again. Please close this page and use that link.</p>
            <p v-else class="body-1 text-left">Invalid link/ID, enter email address to resend link.</p>
            <v-text-field
              label="Email"
              :rules="[rules.required, rules.email]"
              v-model="email"
              v-if="!resent"
            ></v-text-field>
            <!-- <v-btn :loading="rvload" v-if="fail" text>Resend Verification</v-btn> -->
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn :loading="vload" v-if="!resent" class="success float-right" @click="verify">Resend</v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-else
        class="mx-auto my-12"
        style="min-width:350px;max-width:350px;"
      >
        <v-card-title>
          <span class="headline">Audit Review Scheduler</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <p class="body-1 text-left">Enter verification code from email.</p>
            <v-text-field
              label="Code"
              v-model="code"
              :rules="[rules.required]"
              maxlength="5"
            ></v-text-field>
            <p v-if="fail" class="body-1 text-left">Incorrect code, please try again or resend.</p>
            <v-btn :loading="rvload" v-if="fail" @click="prepresend" text>Resend Verification</v-btn>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn :loading="vload" class="success float-right" @click="verify">Verify</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AuthComp',
  components: {
  },
  data: () => ({
    code:"",
    uuid:"",
    vload:false,
    rvload:false,
    provider:{},
    fail:false,
    nouuid:false,
    resent:false,
    email:"",
    rules: {
      required: value => !!value || 'Required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
  }),
  beforeMount: function(){
    this.$root.provider = {};
    if(this.$route.params.uuid){
      console.log("UUID: " + this.$route.params.uuid);
      this.uuid = this.$route.params.uuid;
    }else{
      this.nouuid = true;
    }
  },
  mounted: function(){
  },
  methods:{
    verify(){
      this.vload = true;
      axios.get('https://wh94d7cw29.execute-api.us-east-1.amazonaws.com/prod/edschedverify?uuid=' + this.uuid + '&code=' + this.code + '&email=' + this.email
      ).then(res => {
        //console.log("DATA RETRIEVED: " + JSON.stringify(res));
        let data = res.data;
        if(data == "NA" || data.errorType){
          this.fail = true;
        }else if(data == "NX"){
          this.nouuid = true;
        }else if(data == "RE"){
          this.resent = true;
        }else{
          this.$root.provider = data;
          this.fail = false;
          this.$router.push({"name":"home"});
        }
        this.vload=false;
        console.log(this.provider);
      }).catch(function (error) {
        console.log(error);
      })
    },
    prepresend(){
      this.nouuid=true;
      this.uuid="";
      this.code="";
    }
  },
};
</script>

<style>
  
</style>